import { render, staticRenderFns } from "./Hint-Updata.vue?vue&type=template&id=ba313156&scoped=true&"
import script from "./Hint-Updata.vue?vue&type=script&lang=js&"
export * from "./Hint-Updata.vue?vue&type=script&lang=js&"
import style0 from "./Hint-Updata.vue?vue&type=style&index=0&id=ba313156&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba313156",
  null
  
)

export default component.exports