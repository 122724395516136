<template>
  <div id="hint">
    <Image-Zoom
      :img="`${$public}/images/my/real-name-authentication-template.jpg`"
      :thumbnail="`${$public}/images/my/real-name-authentication-template.jpg`"
      :zoom="true"
    />
    <div class="text">
      <h2>上传须知：</h2>
      <div>
        <p>
          （1）请您先核查设置实名信息是否填写（身份证号、生日、性别、地址）。
        </p>
        <p>（2）提币地址需填写交易所/钱包FIL主链充币地址。</p>
        <p>（3）手持半身照需拍清字迹。</p>
        <p>（4）如需重新上传照片，点击虚线框即可。</p>
      </div>
    </div>
  </div>
</template>

<script>
import ImageZoom from "@/components/Image-Zoom";
export default {
  components: {
    ImageZoom,
  },
};
</script>

<style lang="less" scoped>
#hint {
  > .text {
    margin-top: 1em;
    color: white;

    > div {
      padding: 0em 0.5em;

      > p {
        margin: 1em 0;
      }
    }
  }
}
</style>
